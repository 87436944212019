import dayjs from 'dayjs';
import { useConfigStore } from '#imports';

export const useCalendarConfig = () => {
  const { currency, currencyConfig } = useConfigStore();
  const { thousand } = currencyConfig!;

  const getFares = (
    calendar: { month: number; year: number },
    faresData?: {
      [key: string]: number;
    },
  ) => {
    if (faresData)
      return Object.keys(faresData)
        .filter((key) => dayjs(key).get('month') === calendar.month && dayjs(key).get('year') === calendar.year)
        .reduce((fares, key) => {
          let fare = faresData![key];

          // formatted currency
          if (thousand === '.') fare = Math.ceil(fare! / 1000);

          return Object.assign(fares, { [key]: fare });
        }, {});
  };

  const formatFarePrice = (farePrice: number) => {
    return useNuxtApp().$c(Math.ceil(farePrice), currency!, { hideSymbol: true });
  };

  return {
    getFares,
    formatFarePrice,
  };
};
